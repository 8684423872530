import gql from 'graphql-tag';

export const SUBSCRIBE_DEVICE_EVENT_GROUP_STATUS = gql`
  subscription deviceEventStatusSubscription($input: DeviceEventStatusSubscriptionInputType) {
    deviceEventStatusSubscription(input: $input) {
      deviceEventId
      current_status
      timestamp
    }
  }
`;

export const DEVICE_EVENT_GROUP_CURRENT_STATUS = gql`
query deviceEventGroupStatus($ids: [String!]) {
  deviceEventGroups(id: $ids) {
    id,
    current_status,
  }
}`;

export const GET_NOTIFICATIONS = gql`
mutation getNotifications(
$deviceId: String,
$startTime: String,
$endTime: String) {
  getNotifications(
  deviceId: $deviceId,
  startTime: $startTime,
  endTime: $endTime) {
    id
    current_status
  }
}`;


export const GET_EVENT_POLLING = gql`
mutation getEventPolling($ids: [String]) {
  getEventPolling(ids: $ids)
}`;
