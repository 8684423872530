interface IProps {
  id?: string;
  icon: React.ComponentType<any>;
  className?: string;
  title?: string;
  style?: any;
  wrapperStyle?: any;
  onClick?: any;
}
const BIcon = ({
  id,
  icon,
  className,
  title,
  style,
  wrapperStyle,
  onClick,
}: IProps) => {
  const IconComponent = icon;
  return (
    <span
      title={title}
      style={{
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '5px',
        marginLeft: '5px',
        ...wrapperStyle,
      }}
    >
      <IconComponent
        id={id}
        style={Object.assign(
          {
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
          style
        )}
        className={className}
        onClick={onClick}
      />
    </span>
  );
};

export default BIcon;
