import { useEffect, useState, useRef } from 'react';

const useScrollComplete = () => {
  const [isScrollComplete, setIsScrollComplete] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const manageScroll = () => {
      if (containerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

        if (Math.ceil(scrollTop + clientHeight) >= scrollHeight) {
          setIsScrollComplete(true);
        }
      }
    };

    if (containerRef.current) {
      containerRef.current.addEventListener('scroll', manageScroll);
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('scroll', manageScroll);
      }
    };
  }); // TODO come back to this - why won't this work with [containerRef] in the deps array?

  return { containerRef, isScrollComplete };
};

export default useScrollComplete;
