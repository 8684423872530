import React, { createContext, useState, useMemo } from 'react';
import { AddCircle } from '@mui/icons-material';
import BDropDown from '../common/BDropDown';
import BConfirm from '../common/BConfirm';
import BTextField from '../common/BTextField';
import {
  generateID,
  sortViewDropdownOptions,
} from '../../utility/utilityFunctions';
import { Backdrop } from '@mui/material';
import BButton from '../common/BButton';
import CancelIcon from '@mui/icons-material/Cancel';
import { commonData } from '../../data/CommonData';
import BToastMessage from '../common/ToastMessage';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IView, IDropdownItem } from '../../types/types';

interface IProps {
  viewsList: IView[];
  [k: string]: any;
}

export default function SaveViewActions({
  viewsList,
  currentView,
  changeView,
  showCreateModal,
  showUpdateModal,
  showDeleteModal,
  showRenameModal,
  toastInfo,
  setToastInfo,
}: IProps) {
  const moreOptions = [
    { name: 'Rename view', action: showRenameModal },
    { name: 'Delete view', action: showDeleteModal },
  ];

  const menuItems = useMemo(() => {
    const views: IDropdownItem[] = viewsList.map(view => ({
      label: view.name,
      value: view.id,
      isDefault: view.default === 'true',
    }));
    return sortViewDropdownOptions(views);
  }, [viewsList]);

  return (
    <>
      {/* When the Event summary window is opened, the backdrop is activated, this dims the background. */}
      <div
        style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}
      >
        {toastInfo && (
          <BToastMessage
            id={generateID('toast', 'pl', 'notification_msg')}
            style={{ marginTop: '40px', marginRight: '20px' }}
            open={toastInfo !== null}
            close={() => {
              setToastInfo(null);
            }}
            severity={toastInfo.type}
            msg={toastInfo.msg}
          />
        )}
        <BDropDown
          id={generateID('drpdwn', 'views', 'select')}
          name='views'
          label='Select View'
          size='small'
          menuItems={menuItems}
          value={currentView?.id}
          shrink={true}
          labelWidth={100}
          fullWidth={false}
          handleChange={event => {
            changeView(event.target.value);
          }}
        />
        <span className='space-right'></span>
        <BButton
          color='primary'
          clickEvent={() => {
            showUpdateModal(); // showModal('UPDATE')
          }}
          id={generateID('btn', 'views', 'update')}
          disabled={currentView?.default === 'true'}
          caption={'Save'}
          style={{ margin: '0 10px' }}
        />
        <BButton
          color='secondary'
          clickEvent={showCreateModal}
          id={generateID('btn', 'views', 'create')}
          caption={'Save As'}
        />
        <span className='space-right'></span>
        <BaxMenu
          disabled={currentView?.default === 'true'}
          options={moreOptions}
        />
      </div>
    </>
  );
}

// https://mui.com/material-ui/react-menu/#max-height-menu for reference
export function BaxMenu({ disabled, options }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label='more'
        id='long-button'
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleClick}
        disabled={disabled}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id='long-menu'
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map(option => (
          <MenuItem key={option.name} onClick={option.action}>
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
