import { SvgIcon } from '@mui/material';
let Insurance = props => {
  return (
    <SvgIcon
      style={props.style}
      height='24'
      width='24'
      className={props.className}
      viewBox='0 0 24 24'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.21516 6.48463H21.7844C22.2459 6.48463 22.6152 6.0231 22.4767 5.56156C22.0152 4.03848 21.369 2.65386 20.4921 1.40771C20.2152 1.03848 19.7075 0.992326 19.4305 1.3154C18.5536 2.14617 17.3075 2.60771 16.0152 2.60771C14.6305 2.60771 13.3844 2.05386 12.4613 1.13079C12.1844 0.853865 11.7229 0.853865 11.4459 1.13079C10.5229 2.05386 9.2767 2.60771 7.89208 2.60771C6.59977 2.60771 5.39978 2.14617 4.4767 1.3154C4.15362 1.03848 3.64593 1.08463 3.41516 1.40771C2.53824 2.60771 1.84593 4.03848 1.43054 5.56156C1.38439 6.0231 1.75362 6.48463 2.21516 6.48463ZM23.0767 9.43848C23.0767 9.0231 22.7536 8.79233 22.3382 8.79233H1.66131C1.24593 8.79233 0.922852 9.0231 0.922852 9.43848V9.57694C0.922852 16.5 5.72285 22.2231 11.9998 23.1C18.2767 22.2231 23.0767 16.5 23.0767 9.6231V9.43848Z'
      />
    </SvgIcon>
  );
};

export default Insurance;
