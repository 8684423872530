import CompanyLogo from '../images/Bardy-logo';
import BEOSPublished from '../images/icons/BEOSPublished';
import {
  BIConDevice,
  BIconButtonPress,
  Transmission,
  Battery,
  Temperature,
  Contact,
  Address,
  Information,
  Alt_Contact,
  Notes,
  ECG_Viewer,
  BIconReports,
  BIconSettings,
  BCheckMark,
  BWarning,
  BEdit,
  BEventReport,
  BBillingReport,
  BClinicalNotes,
  BEventNotification,
  BMoreInfo,
  BPinOff,
  BPinOn,
  TestReport,
  TestReport2,
  BBaseline,
  BEOSDue,
  BEOSReviewDue,
  BEOSApproved,
  BPeriodicSummaryDue,
  BPeriodicSummaryReviewDue,
  BPeriodicSummaryPublished,
  BPeriodicSummaryApproved,
  BEventReportPublished,
  BLock,
  BSpinnerReports,
} from './../images/icons';
import {
  HISTORY_NOTE,
  HISTORY_PROFILE,
  HISTORY_SETTINGS,
  HISTORY_DEVICE,
  HISTORY_EVENTS,
  HISTORY_EVENT_REPORT,
  HISTORY_EOS_REPORT,
  HISTORY_SUMMARY_REPORT,
  HISTORY_CEMSUMMARY_REPORT,
  HISTORY_INSURANCE,
} from './../types/types';

import CriticalIcon from './../images/icons/event_status_32/Critical-icon.png';
import NewIcon from './../images/icons/event_status_32/New-icon.png';
import ReviewIcon from './../images/icons/event_status_32/Review-icon.png';
import PublishedIcon from './../images/icons/event_status_32/Published-icon.png';
import DoneIcon from './../images/icons/event_status_32/Done-icon.png';
import Insurance from '../images/icons/Insurance';

import ErrorInfoIcon from './../images/icons/error-info.svg'
import ErrorWarningIcon from './../images/icons/error-warning.svg'
import ErrorDangerIcon from './../images/icons/error-danger.svg'
import CloseDismissWhite from './../images/icons/close-dismiss-white.svg'

export enum Patient_Events {
  'Temperature' = 'TEMPERATURE',
  'Patient Events' = 'PATIENT_EVENTS',
  'Battery' = 'BATTERY',
  'Data Transmission' = 'DATA_TRANSMISSION',
}

export enum Device_Event_Group_Status {
  'Critical' = 'EQCritical',
  'New' = 'EQNew',
  'Review' = 'EQReview',
  'Published' = 'EQPublished',
  'No Report' = 'EQDone',
  'Done' = 'EQDone',
}

export const ICONS = {
  BATTERY: Battery,
  DATA_TRANSMISSION: Transmission,
  TEMPERATURE: Temperature,
  PATIENT_EVENTS: BIconButtonPress,
  BASELINE: BBaseline,
  PERIODIC_SUMMARY_REPORT_INTERVAL: Notes,
  DEVICE: BIConDevice,
  ADDRESS: Address,
  REPORTS: BIconReports,
  ECG_Viewer: ECG_Viewer,
  INFORMATION: Information,
  INSURANCE: Insurance,
  ALT_CONTACT: Alt_Contact,
  CONTACT: Contact,
  NOTES: Notes,
  SETTINGS: BIconSettings,
  WARNING: BWarning,
  CHECKMARK: BCheckMark,
  EDIT: BEdit,
  SPINNER: BSpinnerReports,
  [HISTORY_PROFILE]: Information,
  [HISTORY_INSURANCE]: Insurance,
  [HISTORY_NOTE]: BClinicalNotes,
  [HISTORY_SETTINGS]: BIconSettings,
  [HISTORY_EVENTS]: BEventNotification,
  [HISTORY_EVENT_REPORT]: BEventReport,
  [HISTORY_SUMMARY_REPORT]: BBillingReport,
  [HISTORY_CEMSUMMARY_REPORT]: BBillingReport,
  [HISTORY_EOS_REPORT]: BBillingReport,
  [HISTORY_DEVICE]: BIConDevice,
  PIN_ON: BPinOn,
  PIN_OFF: BPinOff,
  MORE_INFO: BMoreInfo,
  PDF1: TestReport,
  PDF2: TestReport2,
  // TODO: update with icons - once received from UX Team
  SUMMARY_REPORT: TestReport,
  EVENT_REPORT: TestReport2,
  COMPANY_LOGO: CompanyLogo,
  LOCK: BLock,
  // EOS report states
  eos_due: BEOSDue,
  eos_ready_for_review: BEOSReviewDue,
  eos_published: BEOSPublished,
  eos_accepted: BEOSApproved,
  // EOS report states
  periodic_summary_due: BPeriodicSummaryDue,
  periodic_summary_ready_for_review: BPeriodicSummaryReviewDue,
  periodic_summary_published: BPeriodicSummaryPublished,
  periodic_summary_accepted: BPeriodicSummaryApproved,
  event_published: BEventReportPublished,
  // CEM report states
  cem_summary_due: BEOSDue,
  cem_summary_ready_for_review: BEOSReviewDue,
  cem_summary_published: BEOSPublished,
  cem_summary_accepted: BEOSApproved,
  // Event Queue Device Event Group Status
  EQCritical: CriticalIcon,
  EQNew: NewIcon,
  EQReview: ReviewIcon,
  EQPublished: PublishedIcon,
  EQDone: DoneIcon,
  // Error Handling display to user
  ErrorInfoIcon: ErrorInfoIcon,
  ErrorWarningIcon: ErrorWarningIcon,
  ErrorDangerIcon: ErrorDangerIcon,
  CloseDismissWhite: CloseDismissWhite,
};
