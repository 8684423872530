const text = `The Fellowship of the Ring Book 1, Chapter 1

Summary: Book 1, Chapter 1: A Long-Expected Party

In a hole in the ground there lived a hobbit named Bilbo Baggins. The hole was large and round, and very comfortable to live in, with a garden before it where flowers grew in the sun.

The hobbits had a great liking for gardens, and flowers especially, for the gardens of the Shire were famous all over the land of the Elves in the West. But Bilbo Baggins had a very quiet nature, and he liked his garden best when it was untidy, and nobody came to see it.

The people of the Shire, who were mostly hobbits like himself, were a very busy lot, and they spent all their time either eating or drinking or making money. They were not much bothered about the War of the Ring, which was going on far away in the lands of the Elves and Dwarves, and the Wizards, and the Men of the West. They were busy with their gardens, and their farms, and their families.

Bilbo Baggins was a little different from most of the hobbits. He spent a good deal of his time reading, or rather, looking at pictures of far-off countries in the large books in his study. He had a great longing to go and see the great mountains, and the long rivers, and the wide seas of the West, and the vast forests and dark caves of the South.

But he was a hobbit, and hobbits have no fingers at the ends of their toes, and they are not fond of adventures. They prefer sitting quietly at home, or eating, or playing cards, or going to bed early. They are not wild, uncontrollable, or excitable, like the Woses, or the Elves, or the Dwarves.

Bilbo Baggins was a hobbit, and he lived in a hole in the ground. But he was not a very ordinary hobbit, and he was not contented with his quiet life. He wanted to see the world, and he was determined to go and see it.

One day, when he was sitting in his study, he heard a strange noise outside his window. It sounded like a door being knocked on, but he knew that there were no doors in his garden, and no one ever came to see him. He went to the window and looked out, and there, standing on his doorstep, was a tall, thin man in a long black coat, with a wide-brimmed black hat pulled down over his eyes.

"Good morning, Mr. Baggins," said the man. "I am Gandalf, and I have a job for you."

Bilbo Baggins was very surprised, and he did not know what to say. He had never heard of Gandalf, and he did not know what a job was. He was just about to ask what a job was, when Gandalf spoke again.

"I am looking for someone to share in an adventure that I am arranging, and it's very difficult to find anyone," he said. "I want to go to the Lonely Mountain, and make a surprise attack on the dragon Smaug, and recover the treasure that has been taken from the dwarves. I want you to come with me, if you are willing."

Bilbo Baggins was very surprised, but he did not say anything. He was not sure what to think. He had never been on an adventure before, and he was not sure if he wanted to go on one. He was a hobbit, and hobbits are not fond of adventures.

But Gandalf saw that he was thinking, and he spoke again.

"I will not force you to come, if you do not want to," he said. "I only ask you to consider it. You would be very welcome, and you would have a share in the treasure, if we are successful. But if you do not want to come, that is all right too. I will not hold it against you."

Bilbo Baggins thought for a long time, and he thought about all the things that he had read in his books. He thought about the great mountains, and the long rivers, and the wide seas, and the vast forests, and the dark caves. He thought about the dragons, and the dwarves, and the Elves, and the Wizards, and the Men of the West. He thought about all the adventures that he had always dreamed of, but had never thought he would ever have.

And he thought about his quiet life, and his comfortable hole in the ground, and his garden, and his books. He thought about his family, and his friends, and his peaceful, ordinary hobbit life.

And in the end, he made up his mind. He would go on the adventure. He would see the world, and he would have an adventure. He would go to the Lonely Mountain, and he would fight the dragon Smaug, and he would recover the treasure.

And so, Bilbo Baggins, the hobbit, set out on his adventure, and he never looked back. He went to the Lonely Mountain, and he fought the dragon Smaug, and he recovered the treasure. And he had many adventures, and he saw many strange and wonderful things, and he became famous all over the land of the Elves in the West.

And he lived a long and happy life, and he was very contented, and he never regretted his decision to go on the adventure. And he told his story to his grandchildren, and they told it to their grandchildren, and it was passed down from generation to generation, and it became a great and famous tale, and it was called The Hobbit.

Because of the stories and wealth he brought back from his adventures, Bilbo Baggins is the most famous hobbit in Hobbiton. He is also considered a bit strange, however. The fact that he receives visits at his house, Bag End, from Elves, Dwarves, and the wizard Gandalf make him the object of some slight suspicion. In addition, ever since Bilbo came back to the Shire with the ring—which he has kept secret from nearly everyone—he has not seemed to age at all. In fact, he reaches his 111th birthday virtually unchanged.

When Bilbo announces that he is throwing a grand party for his “eleventy-first” birthday, everyone in the Shire takes interest. After extensive and elaborate preparation, the day of Bilbo’s birthday finally arrives. All of Hobbiton has a fine time eating, drinking, and watching the spectacular fireworks provided by Gandalf.

As dinner winds down, Bilbo rises and asks to speak to the assembled guests. The speech is short. Just as Bilbo is starting to lose his audience’s attention, he announces that he is leaving, and he suddenly disappears in a flash of light. The party guests are not amused, and they return, muttering, to their eating and drinking.
`;

export default text;
