import useScrollComplete from '../../utility/hooks/useScrollComplete';
import BSaveCancel from '../common/BSaveCancel';
import { generateID, getPrettyDate } from '../../utility/utilityFunctions';
import { useContext, useMemo, useRef, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import BConfirm from '../common/BConfirm';
import Buffer from 'buffer';
import { GET_TERMS_OF_USE, SIGN_TERM_OF_USE } from '../../data/queries/login';
import { useQuery } from '@apollo/client';
import { AccessPermissionsContext } from '../../auth/useAccessPermissions';
import BSpinner from '../common/BSpinner';
import { PORTALS } from '../../types/constants';
import { useSendAuditTrailLogMessage } from '../../data/queries/auditTrailLogMessage';
import { useLocation, useNavigate } from 'react-router-dom';
import BButton from '../common/BButton';
import { MMMM_DD_YYYY } from '../../types/types';

const TermsOfUse = () => {
  const locationDetails = useLocation();
  const navigate = useNavigate();
  const viewOnlyRequested = locationDetails?.state?.viewOnlyRequested || false;
  const { legalDocSigned, saveUserAgreement } = useContext(
    AccessPermissionsContext
  );
  const getBase64 = statement => {
    let statementToString = Buffer.Buffer.from(statement, 'base64').toString();
    return statementToString;
  };
  const { containerRef, isScrollComplete } = useScrollComplete();
  const [openDialogDeclineTerms, setOpenDialogDeclineTerms] = useState({
    open: false,
  });
  const contentRef = useRef(null);
  const heightVh = 80;

  let {
    loading: termsOfUseLoading,
    error: termsOfUseError,
    data: termsOfUseData,
  } = useQuery(GET_TERMS_OF_USE, {
    skip: legalDocSigned && !viewOnlyRequested ? true : false,
  });

  const user_name = useContext(AccessPermissionsContext)?.name;
  const user_email = useContext(AccessPermissionsContext)?.email;
  const { sendAuditMessage } = useSendAuditTrailLogMessage();

  const termsOfUseStatement = useMemo(
    () =>
      termsOfUseData?.legalDocs?.base64text
        ? getBase64(termsOfUseData.legalDocs.base64text)
        : null,
    [termsOfUseData]
  );

  const termsOfUseID = useMemo(
    () => termsOfUseData?.legalDocs?.id || null,
    [termsOfUseData]
  );

  if (termsOfUseLoading) return <BSpinner />;

  return (
    <>
      <BConfirm
        title='Decline Terms of Use?'
        severity='warning'
        open={openDialogDeclineTerms.open}
        cancelCaption='CLOSE'
        okCaption='I DECLINE'
        setOpen={setOpenDialogDeclineTerms}
        onConfirm={() => {
          sendAuditMessage({
            action: 'DATA_SAVE',
            phi_flag: true,
            description: `${user_name} ${user_email} has declined the Terms of Use.`,
          });
          window.location.href = PORTALS[0].redirect;
        }}
        cancelEvent={() => {
          setOpenDialogDeclineTerms({ open: false });
        }}
      >
        {'You must agree to the Terms of Use to access CAM DxT Portal.'}
      </BConfirm>

      <Grid container style={{ display: 'flex', padding: '15px' }}>
        <Grid
          item
          sm={12}
          ref={containerRef}
          style={{
            display: 'flex',
            maxHeight: `${heightVh}vh`,
            minHeight: '100px',
            overflowY: 'scroll',
          }}
        >
          <Grid ref={contentRef} className='centered-Grid'>
            <Grid>
              <Typography style={{ fontSize: '30px', fontWeight: 'bold' }}>
                Terms of Use
              </Typography>
            </Grid>
            <Grid>
              {viewOnlyRequested ? (
                <Typography style={{ color: 'gray' }}>
                  Last Modified: {getPrettyDate(legalDocSigned, MMMM_DD_YYYY)}
                </Typography>
              ) : null}
            </Grid>
            <Grid style={{ padding: '10px' }}></Grid>
            <Grid style={{ fontSize: '14px' }} className='long-text'>
              <Typography>{termsOfUseStatement}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12} style={{ display: 'flex', paddingTop: '40px' }}>
          {viewOnlyRequested ? (
            <BButton clickEvent={() => navigate('/events')} caption='OK' />
          ) : (
            <BSaveCancel
              style={{ display: 'flex', justifyContent: 'right' }}
              saveId={generateID('btn', 'terms_of_use', 'agree')}
              cancelId={generateID('btn', 'terms_of_use', 'decline')}
              cancelCaption='I Decline'
              saveCaption='I Agree'
              saveDisabled={!isScrollComplete}
              cancelEvent={e => {
                e.preventDefault();
                setOpenDialogDeclineTerms({ open: true });
              }}
              saveEvent={() => {
                saveUserAgreement(termsOfUseID);
                sendAuditMessage({
                  action: 'DATA_SAVE',
                  phi_flag: true,
                  description: `${user_name} ${user_email} has agreed to the Terms of Use.`,
                });
              }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default TermsOfUse;
