import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  split,
  HttpLink,
  ServerParseError,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import { typeDefs, resolvers } from './../utility/ClientUtility';
import { silentRequest } from '../auth/msalConfig';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { from } from '@apollo/client';
import { onError, ErrorResponse } from '@apollo/client/link/error';
import { ErrorData } from '../components/error/ErrorProvider';
import { getErrorSeverity } from '../components/error/getErrorSeverity';
import errorText from '../components/error/errorText.json'

export const createApolloClient = (apiEndpointUrl: string, msal, env: string, addError: (error: ErrorData) => void): ApolloClient<NormalizedCacheObject> => {

  // Apollo client configuration

  const protocol = env === 'local' ? 'http' : 'https'

  const httpFetch = (uri, options) => {
    let xRequestId = "null"; // on purpose a loud bad value for debugging
    return fetch(uri, options).then(response => {
      const headers = response.headers
      xRequestId = headers.get('x-request-id');

      if (response.status >= 400) { // handle 400 and 500 errors
        return Promise.reject(response);
      }

      return response;
    }).catch(error => {
      addError(new ErrorData(3, xRequestId)); // default severity 3
      return Promise.reject(error);
    });
  };

  const httpLink = new HttpLink({
    uri: `${protocol}://${apiEndpointUrl}`,
    fetch: httpFetch
  });

  const wsLink = new GraphQLWsLink(
    createClient({
      url: `wss://${apiEndpointUrl}`,
    })
  );

  const authLink = setContext(async (_, { headers }) => {
    const account = msal.instance.getActiveAccount();
    if (!account) {
      throw new Error('No active account');
    }

    let accessToken;
    try {
      const { accessToken: token } = await msal.instance.acquireTokenSilent({
        scopes: silentRequest.scopes,
        account,
      });
      accessToken = token;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        const { accessToken: token } = await msal.instance.acquireTokenRedirect({
          scopes: silentRequest.scopes,
        });
        accessToken = token;
      } else {
        throw error;
      }
    }

    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${accessToken}`,
      },
    };
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    authLink,
  );

  const errorLink = onError((
    { graphQLErrors,
      networkError,
      operation
    } : ErrorResponse) => {
      const response = operation.getContext()?.response;
      const headers = response?.headers;
      const xRequestId = headers?.get('x-request-id');
      if (graphQLErrors) {
        graphQLErrors.map(({ message, extensions }) => {
          if (extensions && extensions.code && extensions.code === 'UNAUTHENTICATED') {
            addError(
              new ErrorData(
                2,
                "UNAUTHENTICATED ERROR",
                "Unauthenticated Error",
                message,
              ));
          }
          else {
            addError(
              new ErrorData(getErrorSeverity(operation.operationName),
              xRequestId,
              operation.operationName,
              operation.operationName in errorText ?
                errorText[operation.operationName].title
                : "Internal Server Error",
              operation.operationName in errorText ?
                errorText[operation.operationName].message
                : "An unexpected error occured.",
            ));
          }
        });
      }
      if (networkError) {
        const serverParseError = networkError as ServerParseError | any;
        if (serverParseError && serverParseError.response) {
          const response = serverParseError.response;
          const headers = response.headers;
          const xRequestId = headers.get('x-request-id');
          addError(
            new ErrorData(
              getErrorSeverity(operation.operationName),
              xRequestId,
              operation.operationName,
              "Network Error",
              response?.message || "An unexpected network error occurred.",
            )
          );
        } else {
          addError(
            new ErrorData(
              getErrorSeverity(operation.operationName),
              xRequestId,
              operation.operationName,
              "Network Error",
              "An unexpected network error occurred.",
            )
          );
        }
      }
    }
  );

  const linkChain = from([errorLink, splitLink, httpLink]);
  const cache = new InMemoryCache({
    addTypename: false, // to avoid __typename being added to the queries
  });


  return new ApolloClient({
    link: linkChain,
    cache: cache,
    typeDefs,
    resolvers,
  });
};
