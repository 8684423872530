import React, { useState } from 'react';
import { Grid } from '@mui/material';
import BTextField from '../../common/BTextField';
import BAutoComplete from '../../common/BAutoComplete';
import BFormGroupTitle from '../../common/BFormGroupTitle';
import stateList from './../../../data/state.json';
import { generateID } from '../../../utility/utilityFunctions';
import { ICONS } from '../../../utility/IconSelection';
import { AccessPermissionsContext } from '../../../auth/useAccessPermissions';

const PatientAddress = props => {
  const USER_ACCESS = React.useContext(AccessPermissionsContext)?.access;
  const { canUpdatePatient } = USER_ACCESS;

  const getDefaultValue = stateId => {
    for (var i = 0; i < stateList.length; i++) {
      if (stateList[i].value === stateId) {
        return stateList[i];
      }
    }
    return '';
  };
  const [stateDefault, setDefaultState] = useState(
    getDefaultValue(props.address.state_id)
  );

  return (
    <>
      {/* Patient Registration Address section */}
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '20px',
            backgroundColor: 'rgb(238 238 238)',
          }}
        >
          <BFormGroupTitle
            title='Address'
            icon={ICONS['ADDRESS']}
          ></BFormGroupTitle>
        </Grid>
      </Grid>
      <Grid container spacing={2} direction='row'>
        <Grid item xs={12}>
          <BTextField
            label='Address line 1'
            name='address.line1'
            id={generateID('input', 'pr_profile', 'line1')}
            value={props.address.line1}
            required={true}
            shrink={true}
            fullWidth={true}
            disabled={!canUpdatePatient}
            errors={props.errors.address ? props.errors.address.line1 : false}
            touched={
              props.touched.address ? props.touched.address.line1 : false
            }
            handleChange={props.handleChange}
            handleBlur={props.handleBlur}
          />
        </Grid>
        <Grid item xs={12}>
          <BTextField
            label='Address line 2'
            name='address.line2'
            id={generateID('input', 'pr_profile', 'line2')}
            value={props.address.line2}
            required={false}
            shrink={true}
            fullWidth={true}
            disabled={!canUpdatePatient}
            handleChange={props.handleChange}
            handleBlur={props.handleBlur}
          />
        </Grid>
        <Grid item xs={6}>
          <BTextField
            label='City'
            name='address.city'
            id={generateID('input', 'pr_profile', 'city')}
            value={props.address.city}
            required={true}
            shrink={true}
            fullWidth={true}
            disabled={!canUpdatePatient}
            errors={props.errors.address ? props.errors.address.city : false}
            touched={props.touched.address ? props.touched.address.city : false}
            handleChange={props.handleChange}
            handleBlur={props.handleBlur}
          />
        </Grid>
        <Grid item xs={6}>
          <BAutoComplete
            label='State'
            id={generateID('drpdwn_auto', 'pr_profile', 'state')}
            name='address.state_id'
            value={props.address.state_id}
            inputValue={stateDefault}
            required={true}
            multiple={false}
            errors={
              props.errors.address ? props.errors.address.state_id : false
            }
            touched={
              props.touched.address ? props.touched.address.state_id : false
            }
            handleBlur={props.handleBlur}
            handleChange={(event, value) => {
              // props.handleChange();
              props.setFieldValue('address.state_id', value ? value.value : '');
              setDefaultState(value);
            }}
            itemList={stateList}
            shrink={true}
            disabled={!canUpdatePatient}
            filterFromStart={true}
          />
        </Grid>
        <Grid item xs={6}>
          <BTextField
            label='Zip Code'
            name='address.zip_code'
            id={generateID('input', 'pr_profile', 'zip_code')}
            value={props.address.zip_code}
            required={true}
            shrink={true}
            fullWidth={true}
            disabled={!canUpdatePatient}
            errors={
              props.errors.address ? props.errors.address.zip_code : false
            }
            touched={
              props.touched.address ? props.touched.address.zip_code : false
            }
            handleChange={props.handleChange}
            handleBlur={props.handleBlur}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PatientAddress;
