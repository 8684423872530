import { getPrettyDate } from '../../utilityFunctions';
import { getEventCriteriaValue } from '../settings/SettingOperations';
import {
  HISTORY_PROFILE,
  HISTORY_NOTE,
  HISTORY_SETTINGS,
  HISTORY_DEVICE,
  HISTORY_EVENTS,
  HISTORY_SUMMARY_REPORT,
  HISTORY_EVENT_REPORT,
  DD_MMM_C_YYYY,
  HISTORY_EOS_REPORT,
  HISTORY_CEMSUMMARY_REPORT,
  HISTORY_INSURANCE,
} from './../../../types/types';
import { sortBy } from 'lodash';
import { isValidUUIDv4 } from '../../utilityFunctions';

const DF_MONTH = 'MMM';
const DF_YEAR = 'YYYY';
const DF_DAY = 'D';
const mapProfileFields = {
  first_name: 'First Name',
  last_name: 'Last Name',
  date_of_birth: 'Date of Birth',
  sex: 'Sex',
  pacemaker: 'Pacemaker',
  patient_identifier: 'Patient Identifier',
  physician_id: 'Physician Id',
  phone: 'Primary Phone',
  other_phone: 'Secondary Phone',
  notes: 'Patient Notes',
  line1: 'Address Line1',
  line2: 'Address Line2',
  city: 'City',
  state_id: 'State',
  zip_code: 'Zip Code',
  phone_type: 'Phone Type',
  other_phone_type: 'Other Phone Type',
};

const mapInsuranceFields = {
  insurance_provider: 'Insurance Provider',
  member_id: 'Member ID',
};

const mapDeviceFields = {
  referring_provider: 'Referring Provider',
  implantId: 'Device SN',
  prescribing_provider: 'Prescribing Provider',
  active: 'Device Activation',
  deviceId: 'Device SN',
  prescribed_wear_time_days: 'Prescribed Wear Time',
  type: 'Type',
  application: 'Application',
  activation_status: 'Activation Status',
  created_at: 'Created At',
  updated_at: 'Updated At',
};

export const formReportStruct = (data, patientDetails, devices_data) => {
  let historyList = [];
  let historyValue = {};
  let timelineList = [];
  let timelineValue = {};
  let monthYearMap = {};
  let day;
  let month = '';
  let year;
  let monthYearIndex = -1;
  let timelineTypeValue = {};
  let topPinnedNote = '';

  // form the profile change text
  const getProfileDesc = profileChangesList => {
    const profileList = [];
    const changesList = [];
    profileChangesList &&
      profileChangesList.map(profileItem => {
        const changes = [];
        let lhsValue;
        let rhsValue;
        const profileField = mapProfileFields[profileItem.path[0]];
        if (profileField) {
          try {
            profileItem.path[0] == 'date_of_birth'
              ? (lhsValue = getPrettyDate(profileItem.rhs, DD_MMM_C_YYYY))
              : (lhsValue = profileItem.lhs);
            profileItem.path[0] == 'date_of_birth'
              ? (rhsValue = getPrettyDate(profileItem.rhs, DD_MMM_C_YYYY))
              : (rhsValue = profileItem.rhs);
            profileList.push(profileField); // just add changed field names for description text
            changes.push(profileField);
            changes.push(lhsValue);
            changes.push(rhsValue);
            changesList.push(changes); // add all changes
          } catch (e) {
            console.log(JSON.stringify(e));
          }
        }
      });
    return { description: profileList.join(', '), changes: changesList };
  };

  const getInsuranceDesc = insuranceChangesList => {
    const insuranceList = [];
    const changesList = [];
    insuranceChangesList &&
      insuranceChangesList.map(insuranceItem => {
        const changes = [];
        let lhsValue;
        let rhsValue;
        const profileField = mapInsuranceFields[insuranceItem.path[0]];
        if (profileField) {
          try {
            lhsValue = insuranceItem.lhs;
            rhsValue = insuranceItem.rhs;
            insuranceList.push(profileField); // just add changed field names for description text
            changes.push(profileField);
            changes.push(lhsValue);
            changes.push(rhsValue);
            changesList.push(changes); // add all changes
          } catch (e) {
            console.log(JSON.stringify(e));
          }
        }
      });
    return { description: insuranceList.join(', '), changes: changesList };
  };
  // form the device change text
  const getDeviceDesc = deviceChangesList => {
    const deviceList = [];
    const changesList = [];
    deviceChangesList &&
      deviceChangesList.map(deviceItem => {
        const changes = [];
        let lhsValue;
        let rhsValue;
        const deviceField = mapDeviceFields[deviceItem.path[0]];
        try {
          lhsValue = deviceItem.lhs;
          rhsValue = deviceItem.rhs;
          // convert true/false to on/off for user comprehension
          lhsValue = booleanToOffOn(lhsValue);
          rhsValue = booleanToOffOn(rhsValue);
          // Don't display uuids to the user
          if (!isValidUUIDv4(lhsValue) && !isValidUUIDv4(rhsValue)) {
            deviceList.push(deviceField); // just add changed field names for description text
            changes.push(deviceField);
            changes.push(lhsValue);
            changes.push(rhsValue);
            changesList.push(changes); // add all changes
          }
        } catch (e) {
          console.log(JSON.stringify(e));
        }
      });
    return { description: deviceList.join(', '), changes: changesList };
  };

  const booleanToOffOn = (bool) => {
    switch (typeof(bool)) {
      case 'boolean':
        return bool ? 'On' : 'Off';
      case 'string':
        if (bool === 'false') return 'Off';
        if (bool === 'true') return 'On';
    }
    return bool;
  }

  // form the setting change text
  const getSettingDesc = (settingChangesList, notes) => {
    let settingNotes = notes || 'NA';
    let settingList = new Set();
    let changesList = [];
    settingChangesList &&
      settingChangesList.map(settingItem => {
        let settingChange = '';
        const change = [];
        settingChange = settingItem.path[1]['setting_abbr'];
        if (settingItem.path[0] == 'severity') {
          change.push(settingItem.path[1]['setting_abbr'] + ' Level');
          change.push(settingItem.lhs);
          change.push(settingItem.rhs);
        } else if (settingItem.path[0] == 'value') {
          change.push(
            settingItem.path[1]['setting_abbr'] +
              ' ' +
              settingItem.path[1]['threshold_name']
          );
          change.push(
            getEventCriteriaValue(
              settingItem.path[1]['threshold_name'],
              settingItem.lhs
            )
          );
          change.push(
            getEventCriteriaValue(
              settingItem.path[1]['threshold_name'],
              settingItem.rhs
            )
          );
        } else {
          //console.log('No change to settings: ', settingChangesList); // remove spammy console log
        }

        if (
          settingItem.path[0] == 'severity' ||
          settingItem.path[0] == 'value'
        ) {
          settingList.add(settingChange);
          changesList.push(change);
        }
      });
    return {
      descriptionText:
        Array.from(settingList).join(', ') + ' | Notes: ' + settingNotes,
      changes: changesList,
    };
  };

  // form the event description
  const getEventDesc = eventChangesList => {
    // format required to be same as input to patientlistDeviceEvent
    let eventList = [];
    let patientEventList = [];
    let eventValue = {};
    let endTime = '';
    let deviceId = '';

    eventChangesList.map(eventItem => {
      eventValue['type'] = eventItem.abbr_type;
      eventValue['severity'] = eventItem.triggered ? eventItem.severity : 'off';
      eventValue['abbr'] = eventItem.abbr;
      eventValue['name'] = eventItem.name;
      eventValue['order'] = eventItem.display_order;
      eventValue['value'] = eventItem.value;
      endTime = eventItem.endTime ? eventItem.endTime : endTime;
      deviceId = devices_data[0]?.deviceId || '';
      if (eventItem.abbr_type == 'string') {
        eventList.push(eventValue);
      } else {
        patientEventList.push(eventValue);
      }
      eventValue = {};
    });
    // sort w.r.t the severity and then order weight
    eventList = sortBy(eventList, [
      function (item) {
        return item.severity;
      },
      function (item) {
        return item.order;
      },
    ]);
    patientEventList = sortBy(patientEventList, [
      function (item) {
        return item.severity;
      },
      function (item) {
        return item.order;
      },
    ]);
    return { eventList, patientEventList, endTime, deviceId };
  };

  // form the event report description
  const getEventReportDesc = () => {
    return 'Event Report published';
  };

  // form the summary report description
  const getSummaryReportDesc = () => {
    return 'MCT Periodic Summary Report published';
  };

  // form the summary report description
  const getEOSReportDesc = source => {
    switch (source) {
      case 'approve':
        return 'MCT Summary Report Approved';
      case 'update':
        return 'MCT Summary Report Updated';
      case 'publish':
        return 'MCT Summary Report Published';
      default:
        return 'MCT Summary Report Published';
    }
  };

  // form the summary report description
  const getCEMSummaryReportDesc = () => {
    return 'CEM Summary Report published';
  };

  // flatten the structure received from the api call into the list form, for better population in table
  let firstEOSReport = null;
  data = data.map(historyItem => {
    // history list => common properites for all the types
    historyValue['type'] = historyItem.type;
    historyValue['createdAt'] = historyItem.created_at;
    historyValue['order'] = historyItem.pinned_order || -1;
    historyValue['id'] = historyItem.id;

    // history timeline
    month = getPrettyDate(historyItem.created_at, DF_MONTH, true);
    year = Number(getPrettyDate(historyItem.created_at, DF_YEAR, true));
    day = Number(getPrettyDate(historyItem.created_at, DF_DAY, true));
    timelineTypeValue = {
      id: historyItem.id,
      type: historyItem.type,
      day: day,
    };

    // check if the month year entry is alraedy done
    if (month + '_' + year in monthYearMap) {
      monthYearIndex = monthYearMap[month + '_' + year];
    } else {
      // if not added
      monthYearMap[month + '_' + year] = timelineList.length; // add the index of the corresponding month year as the value
      timelineValue['month'] = month;
      timelineValue['year'] = year;
      timelineValue['row'] = Array.from({ length: 4 }, u => ({ data: [] }));
      timelineList.push(timelineValue);
      monthYearIndex = monthYearMap[month + '_' + year];
    }

    switch (historyItem.type) {
      // Clinical notes
      case HISTORY_NOTE: {
        historyValue['descriptionText'] = historyItem.description.text;
        historyValue['order'] = historyItem.pinned_order || -1; // replace when the field in API exists
        historyValue['isPinned'] = historyValue['order'] > 0 ? true : false; // replace when the field in API exists
        timelineList[monthYearIndex].row[1]['data'].push(timelineTypeValue);
        break;
      }
      // Patient profile
      case HISTORY_PROFILE: {
        const desc = getProfileDesc(historyItem.description.changes);
        historyValue['descriptionText'] = desc.description;
        historyValue['changes'] = desc.changes;
        timelineList[monthYearIndex].row[3]['data'].push(timelineTypeValue);
        break;
      }
      // Patient settings
      case HISTORY_SETTINGS: {
        const desc = getSettingDesc(
          historyItem.description.changes,
          historyItem.description.notes
        );
        historyValue['descriptionText'] = desc.descriptionText;
        historyValue['changes'] = desc.changes;
        historyValue['notes'] = historyItem.description.notes;
        timelineList[monthYearIndex].row[3]['data'].push(timelineTypeValue);
        break;
      }

      // Device
      case HISTORY_DEVICE: {
        const desc = getDeviceDesc(historyItem.description.changes);
        historyValue['descriptionText'] = desc.description;
        historyValue['changes'] = desc.changes;
        timelineList[monthYearIndex].row[3]['data'].push(timelineTypeValue);
        break;
      }
      // Events
      case HISTORY_EVENTS: {
        historyValue['events'] = getEventDesc(historyItem.description.events);
        timelineList[monthYearIndex].row[0]['data'].push(timelineTypeValue);
        break;
      }
      // Summary Reports - Periodic, CEM and EOS summary Report
      case HISTORY_SUMMARY_REPORT: {
        historyValue['descriptionText'] = getSummaryReportDesc();
        historyValue['link'] = historyItem.description.link;
        timelineList[monthYearIndex].row[2]['data'].push(timelineTypeValue);
        break;
      }

      case HISTORY_CEMSUMMARY_REPORT: {
        historyValue['descriptionText'] = getCEMSummaryReportDesc();
        historyValue['link'] = historyItem.description.link;
        timelineList[monthYearIndex].row[2]['data'].push(timelineTypeValue);
        break;
      }

      case HISTORY_INSURANCE: {
        const desc = getInsuranceDesc(historyItem.description.changes);
        historyValue['descriptionText'] = desc.description;
        historyValue['changes'] = desc.changes;
        break;
      }

      case HISTORY_EOS_REPORT: {
        historyValue['descriptionText'] = getEOSReportDesc(
          historyItem?.description?.source
        );
        if (!firstEOSReport) {
          historyValue['link'] = historyItem.description.link;
          firstEOSReport = historyItem.description.link;
        } else {
          historyValue['link'] = null;
        }

        timelineList[monthYearIndex].row[2]['data'].push(timelineTypeValue);
        break;
      }
      // Event Report
      case HISTORY_EVENT_REPORT: {
        historyValue['descriptionText'] = getEventReportDesc();
        historyValue['link'] = historyItem.description.link;
        historyValue['reportId'] = historyItem.description?.report_id;
        timelineList[monthYearIndex].row[2]['data'].push(timelineTypeValue);
        break;
      }
      default: {
        console.log('Error!');
      }
    }
    historyList.push(historyValue);
    historyValue = {}; // reset the value for next history event
    timelineValue = {};
    timelineTypeValue = {};
    month = '';
    year = null;
  });
  timelineList.reverse();
  return { historyList, timelineList };
};
