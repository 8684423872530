import { useState, createContext, Context, useEffect } from 'react';
import { getErrorSeverity } from './getErrorSeverity';
import errorText from './errorText.json'

export class ErrorData {
  severity: number;
  code: string;
  name: string;
  title: string;
  message: string;
  constructor(
    severity: number,
    code: string,
    name: string = "unknown",
    title: string = "Internal Server Error",
    message: string = "An error occurred."
  ) {
    this.severity = severity;
    this.code = code;
    this.name = name;
    this.title = title;
    this.message = message;
  }
}

type IErrorProviderContext = Context<{
  errors: ErrorData[];
  addError: (error: ErrorData) => void;
  removeError: (index: number) => void;
  isSuppressingErrors: boolean;
  dismissAllErrors: () => void;
}>;

export const ErrorProviderContext: IErrorProviderContext = createContext({
  errors: [],
  addError: null,
  removeError: null,
  isSuppressingErrors: false,
  dismissAllErrors: () => {},
});

export const ErrorProvider = ({ children }): any => {
  const [errors, setErrors] = useState<ErrorData[]>([]);
  const [isSuppressingErrors, setIsSuppressingErrors] =
    useState<boolean>(false);

  // To suppress errors, add '?suppressErrors=true' to the url, only needed once
  // you can add '?suppressErrors=false' to revert to normal behavior, only needed once
  useEffect(() => {
    const url = new URL(window.location.href);
    const suppressErrorsValue: boolean =
      url.searchParams.get('suppressErrors') == 'true';
    setIsSuppressingErrors(suppressErrorsValue);
  }, [isSuppressingErrors]);

  const addError = error => {
    if (!error || error === 'null') return; // suppress errors that can't be traced in AppInsights
    if (error.name === 'unknown') return; // suppress unknown errors
    if (error.severity <= 0) return // suppress errors for lowest severities
    setErrors(prevState => [...prevState, error]);
  };
  const removeError = index =>
    setErrors(errors.filter((_, i) => i !== index));
  const dismissAllErrors = () => setErrors([]);

  const value = {
    errors,
    addError,
    removeError,
    isSuppressingErrors,
    dismissAllErrors,
  };

  return (
    <ErrorProviderContext.Provider value={value}>
      {children}
    </ErrorProviderContext.Provider>
  );
};

export default ErrorProvider;
