import { useContext } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Link } from 'react-router-dom';
import { useRouteMatch } from '../utility/RoutesUtility';
import { useMutation, useQuery } from '@apollo/client';
import { SELECTED_PATIENT_ID, SELECT_PATIENT } from '../data/queries/patient';
import { Grid } from '@mui/material';
import BIcon from '../components/common/BIcon';
import { ICONS } from '../utility/IconSelection';
import { Logout } from '@mui/icons-material';
import { useMsal } from '@azure/msal-react';
import { AccessPermissionsContext } from '../auth/useAccessPermissions';
import { useSendAuditTrailLogMessage } from '../data/queries/auditTrailLogMessage';
import UserProfileMenu from '../components/user/UserProfileMenu';

// TODO clean this up
const routeList = [
  '/events',
  '/patient',
  '/patient/new',
  '/patient/:id',
  '/patient/:id/device',
  '/patient/:id/profile',
  '/patients',
  '/admin',
];

const NavigationComponent = () => {
  const { instance } = useMsal();
  const [select] = useMutation(SELECT_PATIENT);
  const routeMatch = useRouteMatch(routeList);
  const USER_ACCESS = useContext(AccessPermissionsContext)?.access;
  const { sendAuditMessage } = useSendAuditTrailLogMessage();

  const resetPatient = () => {
    select({
      variables: { id: null, deviceId: null, isDeviceRegistered: false },
    });
  };
  const { data: { selectedPatientId } = { selectedPatientId: null } } =
    useQuery(SELECTED_PATIENT_ID);
  let currentTab = routeMatch?.pattern?.path;

  return routeList.includes(currentTab) ? (
    <header>
      <h4 className='layout-header'>
        <Grid
          className='mui-fixed'
          spacing={2}
          container
          style={{ display: 'inline-flex', alignItems: 'center' }}
        >
          {/* Company Logo: */}
          <Grid item sm={2} style={{ justifyContent: 'left', display: 'flex' }}>
            <Link
              to='/'
              style={{
                display: `inline`,
                color: `white`,
                textDecoration: `none`,
                width: '50%',
              }}
            >
              <BIcon
                icon={ICONS['COMPANY_LOGO']}
                wrapperStyle={{ width: '100%', height: 'auto' }}
              />
            </Link>
          </Grid>
          <Grid item sm={7}>
            <Tabs
              value={currentTab}
              TabIndicatorProps={{
                style: { background: 'primary', height: '8px', color: 'black' },
              }}
            >
              {USER_ACCESS.navigationMenu.map((menuItem, index) => {
                return menuItem.id !== 'current_patient' ? (
                  (selectedPatientId && menuItem.id !== 'new_patient') || // Hide new patient if current patient is visible
                  !selectedPatientId ? (
                    <Tab
                      key={menuItem.label}
                      sx={{ fontWeight: 'bold' }}
                      label={menuItem.label}
                      type={menuItem.type}
                      value={menuItem.value}
                      to={menuItem.linkTo}
                      onClick={() => {
                        if (
                          ['event_queue', 'patient_list', 'admin'].includes(
                            menuItem.id
                          )
                        )
                          resetPatient();
                      }}
                      component={Link}
                    />
                  ) : null
                ) : selectedPatientId ? ( // Current patient
                  <Tab
                    sx={{ fontWeight: 'bold' }}
                    key={menuItem.label}
                    label={menuItem.label}
                    type={menuItem.type}
                    value={menuItem.value}
                    to={`/patient/${selectedPatientId}`}
                    component={Link}
                  />
                ) : null;
              })}
            </Tabs>
          </Grid>
          <Grid
            item
            sm={3}
            style={{
              display: 'flex',
              justifyContent: 'right',
              paddingRight: '10px',
            }}
          >
            <UserProfileMenu instance={instance}></UserProfileMenu>
          </Grid>
        </Grid>
      </h4>
    </header>
  ) : null;
};

export default NavigationComponent;
