import BIcon from './BIcon';
import { ICONS } from './../../utility/IconSelection';

const BTabStepStatus = ({ completed, titleIncompleteStep = '' }) => {
  return completed ? (
    <BIcon
      icon={ICONS['CHECKMARK']}
      style={{ color: 'green', fontWeight: 'bolder', fontSize: '1rem' }}
    />
  ) : (
    <BIcon
      icon={ICONS['WARNING']}
      title={titleIncompleteStep}
      style={{ color: '#f5a411', fontWeight: 'bolder', fontSize: '1rem' }}
    />
  );
};

export default BTabStepStatus;
