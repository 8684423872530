import gql from 'graphql-tag';

// start local  mutations
export const M_USER_LOGGED = gql`
  mutation setUserLoggedIn($id: Boolean!) {
    setUserLoggedIn(userLoggedIn: $id) @client
  }
`;
export const Q_USER_LOGGED = gql`
  query userLoggedIn {
    userLoggedIn @client
  }
`;
// end local mutations

export const GET_USER_ROLE = gql`
  query GET_USER_ROLE($id: String) {
    user(id: $id) {
      signed_legal_doc_timestamp
      first_name
      last_name
      email
      organization {
        name
      }
      profile {
        id
        name
      }
      permissions {
        id
        name
      }
    }
  }
`;

export const GET_TERMS_OF_USE = gql`
  query GET_TERMS_OF_USE {
    legalDocs {
      id
      title
      version
      base64text
    }
  }
`;

export const SIGN_TERM_OF_USE = gql`
  mutation signLegalDoc($legal_doc_id: String) {
    signLegalDoc(legal_doc_id: $legal_doc_id)
  }
`;
