import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
} from '@mui/material';

const BRadio = ({
  id,
  label,
  value,
  name,
  options,
  handleChange,
  row = false,
  style = {},
  disabled,
}) => {
  return (
    <FormControl id={id} disabled={disabled}>
      <FormLabel>{label}</FormLabel>
      <RadioGroup
        row={row}
        style={style}
        id={id}
        name={name}
        color='default'
        value={value}
        onChange={handleChange}
      >
        {options.map(o => (
          <FormControlLabel
            value={o.value}
            control={<Radio />}
            label={o.label}
            key={o.value}
            disabled={o.disabled}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default BRadio;
