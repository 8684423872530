// This component creates Title for the form group. Form group contains related form fields.
import { Typography } from '@mui/material';
import BIcon from './BIcon';

interface IProps {
  title: string;
  icon: (props: any) => JSX.Element;
}

const BFormGroupTitle = ({ title, icon }: IProps) => {
  return (
    <Typography
      style={{
        display: 'flex',
        alignItems: 'center',
        textTransform: 'uppercase',
        lineHeight: '2.0',
      }}
    >
      <BIcon
        style={{
          marginRight: '5px',
          display: icon ? 'inherit' : 'none',
        }}
        icon={icon}
      />
      {title}
    </Typography>
  );
};

export default BFormGroupTitle;
