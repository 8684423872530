const severityMapping = {
  'getAllViewsOfType': 2,
  'eventsdefaultAndList': 3,
  'defaultAndList': 3,
  'deviceEventGroupStatusTypes': 2,
  'getPatientHeader' : 2,
  'Get_Organizations_ALL': 3,
  'GetAdminSettings': 3,
  'getDeviceDataFromPatientId': 3,
  'getPatientHistory': 2,
  'indications': 1,
  'getPatient': 3,
  'organizations': 3,
  'GetOrgID': 2,
  'GetPatientSettings': 3,
  'getReportData': 2,
  'pendingReportRequests': 1,
  'updateView': 2,
  'createView': 2,
  'deleteView': 1,
  'getReportLocks': 0,
  'updateOrgSettings': 3,
  'createOrgSettings': 3,
  'renameThresholdSettings': 2,
  'deleteThresholdSettings': 2,
  'setDefaultNewPatient': 3,
  'getReportLink': 3,
  'createOrUpdateDevice': 3,
  'activateDevice': 1,
  'deactivateDevice': 3,
  'deleteDevice': 3,
  'createNote': 2,
  'togglePinned': 1,
  'deletePatient': 3,
  'setReportViewed': 2,
  'createPatient': 3,
  'updatePatient': 2,
  'updatePatientSettings': 3,
  'approveReport': 3,
  'BDMWebSendAuditTrailLogMessage': 0,
  'getEventPolling': 0
}

export function getErrorSeverity(operationName: string) : number {
  if (operationName in severityMapping)
    return severityMapping[operationName];
  return -1
}