import gql from 'graphql-tag';

export const SUBSCRIBE_DEVICE_EVENT_GROUP_STATUS = gql`
  subscription ($input: DeviceEventStatusSubscriptionInputType) {
    deviceEventStatusSubscription(input: $input) {
      deviceEventId
      current_status
      timestamp
    }
  }
`;
