import gql from 'graphql-tag';

export const INDICATIONS = gql`
  {
    indications(limit: 1000) {
      id
      code
      desc
    }
  }
`;
