import { useContext, useState } from 'react';
import BButton from '../common/BButton';
import ErrorCard from './ErrorCard';
import { ErrorProviderContext } from './ErrorProvider';
import messageFormat from '../../utility/messageToUser.json';

const ErrorContainerHeader = ({ errors, isStacked, handleToggleStack, handleDismissAll }) =>
  errors.length > 1 ? (
    <div className='error-container-header'>
      <div className='error-count'>
        <b>{errors.length} Errors </b>
        <button className='error-dismiss-all' onClick={handleToggleStack}>
          {isStacked ? `Show All` : `Hide`}
        </button>
      </div>
      <button className='error-dismiss-all' onClick={handleDismissAll}>
        Dismiss all errors
      </button>
    </div>
  ) : null;

const ErrorContainer = () => {
  const { errors, removeError, isSuppressingErrors, dismissAllErrors } =
    useContext(ErrorProviderContext);

  const [isStacked, setIsStacked] = useState<boolean>(true);

  const toggleStack = () => { setIsStacked(!isStacked); }

  if (!errors.length) return null;

  // test team specifically asked to suppress errors for screenshot purposes
  if (isSuppressingErrors && errors.length > 0)
    return (
      <div style={{ color: 'red' }}>
        <p> {errors.length} errors suppressed</p>
      </div>
    );

  return (
    <div className={`errors`}>
      <hr />
      <ErrorContainerHeader
        errors={errors}
        isStacked={isStacked}
        handleToggleStack={toggleStack}
        handleDismissAll={dismissAllErrors}
      />
      {isStacked &&
      <div className={`error-container`}>
        {errors.map((error, index) => (
          <ErrorCard
            key={index}
            error={error}
            onClose={() => removeError(index)}
            isStacked={isStacked}
          />
        ))}
      </div>
      }
      {!isStacked &&
        <div className={`error-container`}>
        {errors.map((error, index) => (
          <ErrorCard
            key={index}
            error={error}
            onClose={() => removeError(index)}
            isStacked={isStacked}
          />
        ))}
      </div>
      }
    </div>
  );
};

export default ErrorContainer;
