import { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import BButton from '../common/BButton';
import raw from '../../data/files/privacypolicy';

const PrivacyPolicy = () => {
  const locationDetails = useLocation();
  const navigate = useNavigate();
  const [privacyPolicyDesc, setPrivacyPolicyDesc] = useState('');
  const heightVh = 80;
  useEffect(() => {
    setPrivacyPolicyDesc(raw);
  }, []);
  return (
    <>
      <Grid container style={{ display: 'flex', padding: '20px' }}>
        <Grid
          item
          sm={12}
          style={{
            display: 'flex',
            maxHeight: `${heightVh}vh`,
            minHeight: '100px',
            overflowY: 'scroll',
          }}
        >
          <Grid className='centered-Grid'>
            <Grid style={{ fontSize: '30px', fontWeight: 'bold' }}>
              Privacy Policy
            </Grid>
            <Grid style={{ padding: '20px' }}></Grid>
            <Grid style={{ fontSize: '14px' }} className='long-text'>
              <Typography>{privacyPolicyDesc}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12} style={{ display: 'flex', paddingTop: '40px' }}>
          <BButton clickEvent={() => navigate('/events')} caption='OK' />
        </Grid>
      </Grid>
    </>
  );
};

export default PrivacyPolicy;
