import { gql } from '@apollo/client';

export const GET_INSURANCE = gql`
  query insurance($patientId: String) {
    patient(id: $patientId) {
      insurance {
        id
        member_id
        insurance_provider
      }
    }
  }
`;

export const CREATE_UPDATE_INSURANCE = gql`
  mutation updateInsurance($input: InsuranceInputType) {
    updateInsurance(input: $input)
  }
`;
