import './ErrorCard.css';
import { ErrorData } from './ErrorProvider';
import { Tooltip } from '@mui/material';
import { ICONS } from '../../utility/IconSelection';
export interface IErrorCard {
  error: ErrorData;
  onClose: () => void;
  isStacked: boolean;
}

const ErrorCard = (props: IErrorCard) => {
  const customerServicePhone = "+1-844-777-9283";
  const customerServiceEmail = "US_FLC_CAMSolutions_customerservice@baxter.com";

  const className =
    props.isStacked ? "error-card-severity" + props.error.severity
    : "error-card-expanded-severity" + props.error.severity;

  return (
    <div className={className}>
      <div className="error-card-content">
      <div className='error-card-left'>
        <div className='error-card-icon'>
          <Tooltip title={props.error.name}>
            <img src={
              props.error.severity >= 3 && ICONS['ErrorDangerIcon']
            || props.error.severity === 2 && ICONS['ErrorWarningIcon']
            || ICONS['ErrorInfoIcon']} />
          </Tooltip>
        </div>
      </div>
      <div className='error-card-right'>
        <div className='error-card-header'>
          <div className='error-card-title'>
            {props.error.title}
          </div>
          <div className='error-card-close' onClick={props.onClose}>
            <img src={ICONS['CloseDismissWhite']} onClick={props.onClose} />
          </div>
        </div>
        <div className='error-card-body'>
          <span className='error-card-message'>{props.error.message}</span>
          <span className='error-card-code'>Error Code: {props.error.code}</span>
        </div>
          {props.error.severity >= 3 &&
            <div className='error-card-contact'>
              <div className='error-card-contact-details'>
                <Tooltip title={customerServiceEmail}>
                  {<a href={"mailto:" + customerServiceEmail}>Contact Support</a>}
                </Tooltip>
              </div>
              <div className='error-card-contact-details'>
                {"Call " + customerServicePhone}
              </div>
              <div className='error-card-contact-details'>
                {"Visit the "}{<a href="https://www.bardydx.com/support/">Support Website</a>}
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default ErrorCard;