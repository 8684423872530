import BButton from './BButton';

const BSaveCancel = props => {
  return (
    <>
      {props.showCancelButton !== false && (
        <BButton
          style={{ width: 'auto' }}
          id={props.cancelId}
          color='secondary'
          submit='button'
          clickEvent={props.cancelEvent}
          caption={props.cancelCaption || 'Cancel'}
        ></BButton>
      )}

      <span className='space-right'></span>
      <BButton
        id={props.saveId}
        variant='contained'
        disabled={props.saveDisabled}
        clickEvent={props.saveEvent}
        color='primary'
        caption={props.saveCaption || 'Save'}
      ></BButton>
    </>
  );
};

export default BSaveCancel;
